import React, { useEffect } from 'react';
import { ThumbsUp } from 'lucide-react';

const ThanksPage = () => {
  useEffect(() => {
    // ブラウザバックを無効化
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };

    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.onpopstate = null;
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center px-4 py-8">
      <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-6 sm:p-8 text-center">
        <ThumbsUp className="mx-auto text-green-500 mb-4" size={48} />
        <h1 className="text-xl sm:text-2xl font-bold text-gray-800 mb-4">
          レビュー投稿が完了しました
        </h1>
        <p className="text-sm sm:text-base text-gray-600">
          ご協力ありがとうございました。お客様のフィードバックは私たちにとって非常に重要です。
        </p>
      </div>
    </div>
  );
};

export default ThanksPage;
