// src/api.ts
import axios from 'axios';
import { getFunctions, httpsCallable } from "firebase/functions";

const API_BASE_URL = 'https://us-central1-review-e880a.cloudfunctions.net'; // あなたのCloud FunctionのURLに置き換えてください

// サブスクリプションの停止
export const cancelSubscription = async (uid: string): Promise<any> => {
    try{
        const response = await axios.post(`${API_BASE_URL}/cancelSubscription`, {
            uid: uid,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        throw new Error('Failed to fetch card status');
    }
};

// サブスクリプションの作成
export const createSubscription = async (uid: string): Promise<any> => {
    try {
        const response = await axios.post(`${API_BASE_URL}/createSubscription`, {
            uid: uid,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        throw new Error('Failed to fetch card status');
    }
};

// サブスクリプションの有効化
export const reactivateSubscription = async (uid: string): Promise<any> => {
    try {
        const response = await axios.post(`${API_BASE_URL}/reactivateSubscription`, {
            uid: uid,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        throw new Error('Failed to fetch card status');
    }
};

// カード登録状態を確認するAPI
export const checkSubscriptionStatus = async (uid: string): Promise<any> => {
    try {
        const response = await axios.post(`${API_BASE_URL}/checkSubscriptionStatus`, {
            uid: uid,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch card status');
    }
};

// カードtoken の 登録を行うAPI
// token 化は別でstripe で行う
export const cardregist = async (uid: string, paymentMethodId: string): Promise<any> => {
    try {
        const response = await axios.post(`${API_BASE_URL}/cardregist`, {
            uid: uid,
            paymentMethodId: paymentMethodId,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response;
    } catch (error) {
        throw new Error('Failed to fetch card status');
    }
};

// カード変更 API
export const cardchange = async (uid: string, paymentMethodId: string): Promise<any> => {
    try {
        const response = await axios.post(`${API_BASE_URL}/cardchange`, {
            uid: uid,
            paymentMethodId: paymentMethodId,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        throw new Error('Failed to fetch card status');
    }
};


// カード登録状態を確認するAPI
export const getPaymentHistory = async (uid: string): Promise<any> => {
    try {
        const response = await axios.post(`${API_BASE_URL}/getPaymentHistory`, {
            uid: uid,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch card status');
    }
};

// 請求書宛名の情報更新
export const recipientUpdate = async (uid: string, userType: string, recipient: string): Promise<any> => {
    try {
        const response = await axios.post(`${API_BASE_URL}/recipientUpdate`, {
            uid: uid,
            userType: userType,
            recipient: recipient
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return
    } catch (error) {
        throw new Error('Failed to fetch card status');
    }
};


// レビュー自動生成関数
export const generateReview = async (
    ratings: any,
    industry: string, 
    treat:string,
    comments:string[]): Promise<any> => {

  try {
    const response = await axios.post(`${API_BASE_URL}/generateText`, {
      ratings: ratings,
      industry: industry,
      treat: treat,
      comments
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    
    return response.data; // レスポンスのデータを返す
  } catch (error) {
    throw new Error('error');
  }
};

// レビュー投稿 スパムチェック関数
export const reviewSend = async (
    docId: string, 
    ratings: any,
    currentReview: string ): Promise<any> => {

  try {
    const response = await axios.post(`${API_BASE_URL}/reviewSend`, {
      docId: docId,
      ratings:ratings,
      currentReview: currentReview
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    
    return response.data; // レスポンスのデータを返す
  } catch (error) {
    throw new Error('error');
  }
};


// PW更新後、セッションを閉じる関数
export const killSession = async (idToken: string): Promise<any> => {

    try {
        const response = await axios.post(`${API_BASE_URL}/killSession`,
            {}
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
            });

        return response.data; // レスポンスのデータを返す
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message);
        } else {
            throw new Error('An unknown error occurred');
        }
    }
};


// 退会処理
export const deleteUser = async (idToken: string): Promise<any> => {

    try {
        const response = await axios.post(`${API_BASE_URL}/deleteUser`,
            {}
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
            });

        return response; // レスポンスのデータを返す
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message);
        } else {
            throw new Error('An unknown error occurred');
        }
    }
};

// インクリメント処理
export const incrementSessionCount = async (pageId: string): Promise<any> => {

    try {
        const response = await axios.post(`${API_BASE_URL}/incrementSessionCount`,
            {pageId: pageId}
            ,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
            });

        return response; // レスポンスのデータを返す
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message);
        } else {
            throw new Error('An unknown error occurred');
        }
    }
};

export const addUser = async (uid: string): Promise<any> => {
    try {
        const response = await axios.post(`${API_BASE_URL}/addUser`,
            { uid: uid }
            ,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
            });

        return response; // レスポンスのデータを返す
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message);
        } else {
            throw new Error('An unknown error occurred');
        }
    }
};