import React from 'react';
import { Link } from 'react-router-dom';
import './css/isReady.css';

const IsReady: React.FC = () => {
    return (
        <div className="ready-container">
            <h2>店舗情報の登録ありがとうございました。</h2>
            <div className="ready-review-create">
            <p>登録いただいた内容でレビュー画面を作成中です。しばらくお待ちください。</p>
            </div>

            <div className="ready-notice">
            <p>通常、1~2営業日でレビュー画面は完成しご利用可能になります。</p>
            <p>※確認事項がある場合には、登録メールアドレスへご連絡させていただきます。</p>
            <p>※ご利用可能になった時点でトライアル開始となります。</p>
            </div>
        </div>
    );
};

export default IsReady;
