import React, { useEffect, useState, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';  // QRCodeCanvasを使用
import domtoimage from 'dom-to-image'; // dom-to-imageをインポート
import { db, auth } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import './css/QRCodeGenerator.css';

const QRCodeGenerator = () => {
  const qrCodeRef = useRef<HTMLDivElement | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transitionUrl, setTransitionUrl] = useState('');
  const [formUrl, setFormUrl] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      setIsSubmitting(true);
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);

          const reviewId = userDoc.data().reviewId;

          if (reviewId) {
            const reviewDocRef = doc(db, 'reviewPageIds', reviewId);
            const reviewDoc = await getDoc(reviewDocRef);
            if (reviewDoc.exists()) {
              const data = reviewDoc.data();
              setTransitionUrl(data?.transitionUrl);
              setFormUrl(`https://review-e880a.web.app/${reviewId}`);
            }
          }
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsSubmitting(false);
      }
    };

    fetchUserData();
  }, []);

  // QRコードをPNGで保存する関数
  const downloadQRCode = () => {
    const canvas = document.getElementById('qrCodeCanvas');
    if (canvas) {
      const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      const link = document.createElement("a");
      link.href = pngUrl;
      link.download = "qr_code.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <div className="qr-code-generator">
        <h2 className="qr-title">QRコード</h2>
        <div className="qr-content">
          <p>
            遷移先URL:{' '}
            <a
              className="qr-link"
              href={transitionUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {transitionUrl}
            </a>
          </p>
          <p>
            フォームURL:{' '}
            <a
              className="qr-link"
              href={formUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formUrl}
            </a>
          </p>
        </div>
        <div className="qr-code-result">
          <h3>生成されたQRコード:</h3>
          <div>
            <QRCodeCanvas id="qrCodeCanvas" value={formUrl} size={128} />
          </div>

          <button className="qr-button" onClick={downloadQRCode}>
            QRコードを保存
          </button>
        </div>
      </div>

      {isSubmitting && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}
    </>
  );
};

export default QRCodeGenerator;
