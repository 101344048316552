import React, { useEffect, useState } from 'react';
import { loadStripe, StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import './css/PaymentForm.css';
import { db, auth } from './firebase';
import { doc, onSnapshot, Timestamp } from 'firebase/firestore';
import { cardchange, cardregist, cancelSubscription, reactivateSubscription, createSubscription } from './api'

import { onAuthStateChanged, signOut } from 'firebase/auth';

import Alert from './Alert'; // 先ほど作成したAlertコンポーネントをインポート

import visaLogo from './image/visa.png';
import jcbLogo from './image/jcb.png';
import masterLogo from './image/master.png';
import amexLogo from './image/amex.png';
import dinersLogo from './image/diners.png';

const stripePromise = loadStripe('pk_test_51Puosi01YgfeVWAKgLIWz8i2PUg2ELyOEMyGEXHLHNOGnTdNSLb0V4QhRY9qHumLGCawkBce8CzSPQKgaGNlfBh300Ya1ov2ak'); // 公開可能キー

interface CardRegistrationProps {
  subscriptionReCreate: () => void;
}

const CardRegistration: React.FC<CardRegistrationProps> = ({ subscriptionReCreate }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState(false); // ロード状態
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [cardBrand, setCardBrand] = useState(''); // カードブランドの状態
  const [cardholderName, setCardholderName] = useState(''); // 名義人の状態
  const [alternateBrand, setAlternateBrand] = useState(true); // JCBとDinersの交互表示用

  const [isOpen, setIsOpen] = useState(false); // ポップアップの表示状態を管理

  // --- カード情報 ---
  const [exp_month, setExp_month] = useState<string | null>(null); //
  const [exp_year, setExp_year] = useState<string | null>(null); //
  const [last4, setLast4] = useState<string | null>(null); //
  const [stripeId, setStripeId] = useState<string | null>(null);

  // --- サブスク情報 ---
  const [subscriptionEndDate, setSubscriptionEndDate] = useState<string | null>(null); //
  const [status, setStatus] = useState<string | null>(null); //
  const [subscriptionCancelStatus, setSubscriptionCancelStatus] = useState(true);

  // trial期限
  const [trialEnd, setTrialEnd] = useState('');
  const [textTrialEnd, setTextTrialEnd] = useState('');

  // チェックボックスの状態
  const [isChecked, setIsChecked] = useState(false);

  // - --------- 確認ダイアログ -------------
  // サブスク停止確認ダイアログ
  const [showStopDialog, setShowStopDialog] = useState(false);
  // サブスク有効化ダイアログ
  const [showReStartDialog, setShowReStartDialog] = useState(false);

  // ------ アラート ---------
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<'success' | 'error'>('success');

  // アラートを表示する関数
  const showAlert = (message: string, type: 'success' | 'error') => {
    setAlertMessage(message);
    setAlertType(type);
  };

  // アラートを閉じるための関数
  const handleCloseAlert = () => {
    setAlertMessage(null);
  };

  //----------------------------------

  // trial期限内判定
  const isTrialed = (timestamp: any): boolean => {
    const today = new Date();

    // Firestore の Timestamp オブジェクトの場合、toDate() メソッドを使用
    let trialEnd: Date;
    if (timestamp.seconds) {
      trialEnd = timestamp.toDate(); // Firestore の Timestamp オブジェクトを Date に変換
    } else {
      // もし普通の Unix タイムスタンプであれば
      trialEnd = new Date(timestamp * 1000);
    }
    const isStillInTrial = trialEnd.getTime() >= today.getTime();
    return isStillInTrial;
  };

  //---------------- 一か月後の日付 -------------------
  const getNextMonthDate = (): string => {
    const currentDate = new Date();
    const nextMonthDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));

    // 年月日をフォーマット
    const year = nextMonthDate.getFullYear();
    const month = String(nextMonthDate.getMonth() + 1).padStart(2, '0'); // 月は0始まりなので+1
    const day = String(nextMonthDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const formatSubscriptionEndDate = (timestamp: Timestamp | number): string => {

    let endDate: Date;

    if (timestamp instanceof Timestamp) {
      endDate = timestamp.toDate();
    } else {
      endDate = new Date(timestamp * 1000);
    }

    // Subtract one day (86400000 milliseconds) from endDate
    endDate = new Date(endDate.getTime() - 86400000);

    // Convert endDate to "YYYY-MM-DD" format
    const year = endDate.getFullYear();
    const month = ('0' + (endDate.getMonth() + 1)).slice(-2); // Month is 0-based, so add 1
    const day = ('0' + endDate.getDate()).slice(-2);

    // Return the formatted date
    return `${year}-${month}-${day}`;
  };


  useEffect(() => {
    const intervalId = setInterval(() => {
      setAlternateBrand((prev) => !prev); // JCBとDinersを交互に表示
    }, 2000); // 2秒間隔で切り替え
    return () => clearInterval(intervalId); // コンポーネントのアンマウント時にクリーンアップ
  }, []);



  // -------- 初期化 -----------
  useEffect(() => {
    const initial = async () => {
      const user = auth.currentUser;
      if (user) {
        const uid = user.uid;
        setIsSubmitting(true);

        try {
          // Firestoreのユーザードキュメントのリスナーを設定
          const unsubscribe = onSnapshot(doc(db, 'users', uid), (docSnapshot) => {
            if (docSnapshot.exists()) {
              const userData = docSnapshot.data();
              setStatus(userData.status);
              setSubscriptionEndDate(formatSubscriptionEndDate(userData.subscriptionEndDate));
              setLast4(userData.cardLast4);
              setExp_month(userData.cardExpMonth);
              setExp_year(userData.cardExpYear);
              setSubscriptionCancelStatus(userData.cancel);
              setTrialEnd(userData.endDate);
              setTextTrialEnd(formatSubscriptionEndDate(userData.endDate));
              setStripeId(userData.customerId)
            }
          }, (error) => {
            console.error("Firestoreのリアルタイム取得エラー:", error);
          });

          // コンポーネントがアンマウントされた時にリスナーを解除
          return () => {
            unsubscribe();
          };
        } catch (error) {
          console.error("初期化処理エラー:", error);
        } finally {
          // 最後に必ずsetIsSubmitting(false)を呼び出す
          setIsSubmitting(false);
        }
      } else {
        setIsSubmitting(false); // ユーザーが存在しない場合にもfalseにする
      }
    };

    initial();
  }, []);

  const confirmStop = () => {
    setShowStopDialog(true); // ポップアップ表示
  };

  const confirmReStart = () => {
    setShowReStartDialog(true); // ポップアップ表示
  };

  // --- ダイアログキャンセル ---
  // ダイアログを非表示にする
  const cancelDialog = () => {
    setShowStopDialog(false);
    setShowReStartDialog(false);
  };


  // カード番号入力の変更イベントを処理する関数
  const handleCardNumberChange = (event: StripeCardNumberElementChangeEvent) => {
    const brand = event.brand || 'unknown';
    setCardBrand(brand); // カードブランドを更新
  };

  // カードブランドの表示ロジック
  const renderCardBrand = () => {
    if (cardBrand === 'unknown' || !cardBrand) {
      return (
        <>
          <img src={visaLogo} alt="Visa" className="brand-logo" />
          <img src={masterLogo} alt="MasterCard" className="brand-logo" />
          <img src={amexLogo} alt="Amex" className="brand-logo" />
          <img src={alternateBrand ? jcbLogo : dinersLogo} alt={alternateBrand ? "JCB" : "Diners"} className="brand-logo" />
        </>
      );
    }
    switch (cardBrand) {
      case 'visa':
        return <img src={visaLogo} alt="Visa" className="brand-logo" />;
      case 'mastercard':
        return <img src={masterLogo} alt="MasterCard" className="brand-logo" />;
      case 'amex':
        return <img src={amexLogo} alt="Amex" className="brand-logo" />;
      case 'jcb':
        return <img src={jcbLogo} alt="JCB" className="brand-logo" />;
      case 'diners':
        return <img src={dinersLogo} alt="Diners" className="brand-logo" />;
      default:
        return 'カードブランド不明';
    }
  };


  // ------------ さぶすく停止 --------------------
  const subscriptionStop = async () => {
    setIsSubmitting(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const response = await cancelSubscription(user.uid);
        showAlert('サブスクリプションが停止されました', 'success');
      } else {
        showAlert('ユーザーがログインしていません。', 'error');
      }
    } catch (error) {
      showAlert('処理に失敗しました', 'error');
    } finally {
      setIsSubmitting(false);
      cancelDialog();
    }
  };

  // ------------ さぶすく再作成 --------------------
  const subscriptionCreate = async () => {
    setIsSubmitting(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const response = await createSubscription(user.uid);
        showAlert('サブスクリプションが有効化されました。', 'success');
      } else {
        showAlert('ユーザーがログインしていません。', 'error');
      }
    } catch (error) {
      showAlert('処理に失敗しました', 'error');
    } finally {
      setIsSubmitting(false);
      cancelDialog();
    }
  };

  // ------------ さぶすく再有効 --------------------
  const subscriptionEnabled = async () => {
    setIsSubmitting(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const response = await reactivateSubscription(user.uid);
        showAlert('サブスクリプションが有効化されました。', 'success');
      } else {
        setErrorMessage('');
        showAlert('ユーザーがログインしていません。', 'error');
      }
    } catch (error) {
      showAlert('処理に失敗しました', 'error');
    } finally {
      setIsSubmitting(false);
      cancelDialog();
    }
  };

  // ----------------------- カード登録 or 変更 ----------------------------
  // カード情報を送信
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');
    setSuccessMessage('');

    if (!stripe || !elements) {
      setIsSubmitting(false);
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    if (!cardNumberElement) {
      setErrorMessage('カード情報を入力してください。');
      setIsSubmitting(false);
      return;
    }


    if (cardholderName === '') {
      setErrorMessage('名義人は必須項目です。');
      setIsSubmitting(false);
      return;
    }

    // カード情報をトークン化
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        name: cardholderName, // 名義人情報を送信
      },
    });

    if (error) {
      setIsSubmitting(false);
      return;
    }

    // ------ カード情報を変更 -------
    try {
      const user = auth.currentUser;
      if (user) {
        const response = await cardchange(user.uid, paymentMethod.id);
        setIsOpen(false);
        showAlert('カード情報が更新されました！', 'success');
      } else {
        setErrorMessage('ユーザーがログインしていません。');
      }
    } catch (error) {
      showAlert('更新に失敗しました。カード情報をご確認ください。', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (

    <>

      <div className="payment-form-head">契約管理</div>
      <div className="payment-form-container">
        <table>
          <tbody>
            <tr>
              <td className="payment-label">ご利用中のプラン</td>
              <td className="payment-value payment-plan">
                {/* status の内容によって表示は変える */}
                {isTrialed(trialEnd) ? (
                  //  ------ トライアル期間中 -------
                  <>
                    <div>トライアル中</div>
                    {status === 'trialing' && subscriptionCancelStatus === true ? (

                      // トライアル & サブスク無効
                      <button className="payment-link-button" onClick={(confirmReStart)}>
                        トライアル終了後も継続する場合はコチラ
                      </button>

                    ) : status === 'trialing' && subscriptionCancelStatus === false ? (

                      // トライアル & サブスク有効
                      <div className='cancel-button'>
                        <button className="payment-link-button cancel-button" onClick={(confirmStop)}>
                          (解約する)
                        </button>
                      </div>

                    ) : (
                      // トライアル & 支払い登録なし
                      <button className="payment-link-button" onClick={() => setIsOpen(true)}>
                        継続するには決済情報の登録が必要です
                      </button>
                    )}
                  </>
                  // -------- ここまでトライアル期間中 ---------
                ) : status === 'active' ? (
                  <>
                    <div>有料会員</div>
                    {subscriptionCancelStatus ? (
                      <div className='cancel-button'>
                        <button className="payment-link-button cancel-button" onClick={(confirmReStart)}>
                          (継続利用するにはこちら)
                        </button>
                      </div>
                    ) : (
                      <div className='cancel-button'>
                        <button className="payment-link-button cancel-button" onClick={(confirmStop)}>
                          (解約する)
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  // 支払い有無で分岐する
                  // stripeId の存在有無で、過去サブスクあるか確認
                  <>
                    <div>無料会員</div>
                    <button className="payment-link-button" onClick={subscriptionReCreate}>
                        (契約手続きをする)
                    </button>
                  </>
                )}

              </td>
            </tr>
            <tr>
              <td className="payment-label">有効期限</td>
              <td className="payment-value">
                {/* status の内容によって期限表示は変える */}
                {/* active trial free は期限表示 */}
                {status === 'active' || status === 'trialing' ? (
                  subscriptionEndDate && (
                    <div>{subscriptionEndDate}</div>
                  )
                ) : status === 'free' ? (
                  textTrialEnd && (
                    <div>{textTrialEnd}</div>
                  )
                ) : (
                  <div>期限切れ</div>
                )}
              </td>
            </tr>

            <tr>
              <td className="payment-label">支払い情報</td>
              <td className="payment-value">
                {last4 != '' && exp_month != '' && exp_year != '' ? (
                  <>
                    <div>
                      番号下4桁:{last4}&nbsp;&nbsp;&nbsp;&nbsp;有効期限:{exp_month}/{exp_year}
                    </div>
                    <button className="payment-link-button" onClick={() => setIsOpen(true)}>
                      (支払い方法を変更する)
                    </button>
                  </>
                ) : (
                  <>
                    <div>
                      登録されている支払い情報はありません。
                    </div>
                    <button className="payment-link-button" onClick={() => setIsOpen(true)}>
                      (支払い方法を登録する)
                    </button>
                  </>
                )}
              </td>
            </tr>

            <tr>
              <td className="payment-label">自動更新設定</td>
              <td className="payment-value">
                {status === 'active' || status === 'trialing' || status === 'free' ? (
                  !subscriptionCancelStatus ? (
                    <>
                      <div>
                        <span className="auto-renew-off">○ ON</span> {subscriptionEndDate}の有効期限切れ時に更新されます。
                      </div>
                      <div>
                        <button className="payment-link-button" onClick={(confirmStop)}>
                          (自動更新を無効にする)
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <span className="auto-renew-off">× OFF</span> {subscriptionEndDate}の有効期限切れ時に解約されます。
                      </div>
                      <div>
                        <button className="payment-link-button" onClick={(confirmReStart)}>
                          (自動更新を有効にする)
                        </button>
                      </div>
                    </>
                  )

                ) : (
                  <div>
                    契約情報がありません。
                  </div>
                )}


              </td>
            </tr>

          </tbody>
        </table>
      </div>

      {/* ポップアップの表示 */}
      {isOpen && (
        <div className="popup-overlay">
          <div className="payment-pop-container">
            {/* ポップアップを閉じるボタン */}
            <button onClick={() => setIsOpen(false)} className="close-popup-button">
              ×
            </button>

            <div className="payment-explan">
              <p>弊社では決済にStripeAPIを利用しています。</p>
              <p>カード情報は弊社では保管せずに、強固なセキュリティを持つStripeで安全に管理されます。</p>
            </div>

            <h2 className="payment-form-title">新しいカード情報の登録</h2>
            <form onSubmit={handleSubmit}>
              <div className="payment-input-group">
                <label htmlFor="card-number" className="payment-input-label">カード番号:</label>
                <div className="card-number-container">
                  <CardNumberElement
                    id="card-number"
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#424770',
                          '::placeholder': { color: '#aab7c4' },
                        },
                        invalid: { color: '#9e2146' },
                      },
                    }}
                    className="payment-card-element"
                    onChange={handleCardNumberChange} // カード番号入力の変更を監視
                  />
                  <div className="card-brand">
                    {renderCardBrand()} {/* カードブランドを表示 */}
                  </div>
                </div>
              </div>

              {/* 有効期限とCVCを横に並べる */}
              <div className="payment-input-row">
                <div className="payment-input-group">
                  <label htmlFor="card-expiry" className="payment-input-label">有効期限:</label>
                  <CardExpiryElement
                    id="card-expiry"
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#424770',
                          '::placeholder': { color: '#aab7c4' },
                        },
                        invalid: { color: '#9e2146' },
                      },
                    }}
                    className="payment-card-element"
                  />
                </div>

                <div className="payment-input-group">
                  <label htmlFor="card-cvc" className="payment-input-label">CVC:</label>
                  <CardCvcElement
                    id="card-cvc"
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#424770',
                          '::placeholder': { color: '#aab7c4' },
                        },
                        invalid: { color: '#9e2146' },
                      },
                    }}
                    className="payment-card-element"
                  />
                </div>
              </div>

              <div className="payment-input-group">
                <label htmlFor="cardholder-name" className="payment-input-label">名義人:</label>
                <input
                  id="cardholder-name"
                  type="text"
                  value={cardholderName}
                  onChange={(e) => setCardholderName(e.target.value)} // 名義人の更新
                  className="payment-input payment-card-element"
                />
              </div>

              <button type="submit" className="payment-submit-button" disabled={isSubmitting}>
                {isSubmitting ? '送信中...' : '変更する'}
              </button>

              {errorMessage && <div className="payment-error-message">{errorMessage}</div>}
              {successMessage && <div className="payment-success-message">{successMessage}</div>}
            </form>
          </div>
        </div>
      )}

      {/* 自動更新無効確認ダイアログ */}
      {showStopDialog && (
        <div className="stop-dialog">
          <div className="stop-dialog-content">
            <p>自動更新を解除しますか？</p>
            <p>※次回更新日よりサービスが無効になります</p>
            <button onClick={subscriptionStop} className="btn-confirm">
              はい
            </button>
            <button onClick={cancelDialog} className="btn-cancel">
              いいえ
            </button>
          </div>
        </div>
      )}

      {showReStartDialog && (
        <div className="stop-dialog">
          <div className="stop-dialog-content">
            <p>自動更新を有効にしますか？</p>
            <p>※次回更新日よりサービスが自動更新されます</p>
            <button onClick={subscriptionEnabled} className="btn-confirm">
              はい
            </button>
            <button onClick={cancelDialog} className="btn-cancel">
              いいえ
            </button>
          </div>
        </div>
      )}

      {isSubmitting && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}

      {/* alertMessage が存在する場合にアラートを表示 */}
      {alertMessage && (
        <Alert message={alertMessage} type={alertType} onClose={handleCloseAlert} />
      )}
    </>
  );
};

const WrappedCardRegistration = ({ subscriptionReCreate }: { subscriptionReCreate: () => void }) => (
  <Elements stripe={stripePromise}>
    <CardRegistration subscriptionReCreate={subscriptionReCreate} />
  </Elements>
);

export default WrappedCardRegistration;
