import React, { useState, useEffect } from 'react';
import './css/EditMemberInfo.css';
import './css/StartForm.css'
import { auth } from './firebase'; // 既存のauthをインポート


const StartForm: React.FC = () => {
  const [industry, setIndustry] = useState('');
  const [storeName, setStoreName] = useState('');
  const [details, setDetailse] = useState('');
  const [treats, setTreats] = useState<string[]>([]); // 施術一覧
  const [inputTreatValue, setInputTreatValue] = useState<string>(""); // 施術内容
  const [transitionUrl, setReviewUrl] = useState('');
  const [questionPreference, setQuestionPreference] = useState('お任せする');
  const [question1, setQuestion1] = useState('');
  const [question2, setQuestion2] = useState('');
  const [question3, setQuestion3] = useState('');
  const [uid, setUid] = useState<string | null>(null); // ユーザーのUIDを保存
  const [loading, setLoading] = useState(true); // ロード中の状態
  const [showConfirmDialog, setShowConfirmDialog] = useState(false); // 確認ダイアログの表示状態
  const [isSubmitting, setIsSubmitting] = useState(false); // ローディング状態を管理

  const [email, setEmail] = useState('') // メールアドレス
  const [treatsError, setTreatsError] = useState('') // 施術名エラー文言

  // Firebase Authenticationからログイン中のユーザーのuidを取得
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUid(user.uid);
        setEmail(user.email);
      } else {
        console.error('ユーザーがログインしていません');
      }
      setLoading(false); // ロード完了
    });

    return () => unsubscribe();
  }, []);

  // フォーム送信ボタン押下時に確認ダイアログを表示
  const handleSubmit = (e: React.FormEvent) => {
    setTreatsError('');
    e.preventDefault();

    if(inputTreatValue){
      setTreats([...treats, inputTreatValue]); // 新しい施術内容を配列に追加
      setInputTreatValue('');
    }

    if(treats.length === 0){
      setTreatsError('施術内容は必須です')
    }else{
      setShowConfirmDialog(true); // 確認ダイアログを表示
    }
  };

  // 実際のフォーム送信処理
  const confirmSubmit = async () => {

    setIsSubmitting(true); // ローディング開始

    const formData = {
      uid,
      email,
      industry,
      storeName,
      treats,
      transitionUrl,
      questionPreference,
      question1,
      question2,
      question3,
    };

    try {
      const response = await fetch('https://us-central1-review-e880a.cloudfunctions.net/userReady', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false); // ローディング終了
    }

    setShowConfirmDialog(false); // ダイアログを閉じる
  };

  // ---- 施術内容 入力変更時の処理 ----
  const handleTreatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // スペースで区切る処理
    if (value.endsWith(" ") || value.endsWith("　") || value.endsWith(",")) {
      const newTreat = value.trim(); // 空白を除去
      if (newTreat) {
        setTreats([...treats, newTreat]); // 新しい施術内容を配列に追加
      }
      setInputTreatValue(""); // 入力フィールドをクリア
    } else {
      setInputTreatValue(value); // 入力フィールドに値を保持
    }
  };

  // 施術内容を削除する処理
  const handleRemoveTreat = (index: number) => {
    const newTreats = treats.filter((_, i) => i !== index); // 指定された施術を削除
    setTreats(newTreats);
  };

  // ダイアログを閉じる処理
  const cancelSubmit = () => {
    setShowConfirmDialog(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="form-container">
      <h2>店舗情報</h2>
      <form onSubmit={handleSubmit}>
        <table className="form-table">
          <tbody>
            <tr>
              <td className="lefttd"><label htmlFor="industry">業種:</label></td>
              <td>
                <input
                  type="text"
                  id="industry"
                  value={industry}
                  onChange={(e) => setIndustry(e.target.value)}
                  required
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p className="tips">例: 飲食、IT、製造業など</p>
              </td>
            </tr>

            <tr>
              <td className="lefttd"><label htmlFor="storeName">店舗名:</label></td>
              <td>
                <input
                  type="text"
                  id="storeName"
                  value={storeName}
                  onChange={(e) => setStoreName(e.target.value)}
                  required
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p className="tips">例: 〇〇歯科、〇〇カフェなど</p>
              </td>
            </tr>

            <tr>
              <td className="lefttd"><label htmlFor="details">施術内容:</label></td>
              <td>
                <input
                  type="text"
                  id="details"
                  value={inputTreatValue}
                  onChange={handleTreatChange}
                />
              </td>
            </tr>
            

            {/* 施術内容の表示 */}
            {treats.length > 0 && (
              <tr>
                <td colSpan={2}>
                  <div>
                    {treats.map((detail, index) => (
                      <div key={index} style={{ display: "inline-block", margin: "5px" }}>
                        <span
                          style={{
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            position: "relative",
                          }}
                        >
                          {detail}
                          <div
                            style={{
                              position: "absolute",
                              top: "-5px",
                              right: "-5px",
                              background: "red",
                              color: "white",
                              borderRadius: "50%",
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => handleRemoveTreat(index)}  // クリックで削除
                          >
                            &times;
                          </div>
                        </span>
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            )}

            {treatsError && (
              <tr>
                <td colSpan={2}>
                  <p className="tips">{treatsError}</p>
                </td>
              </tr>
            )}

            <tr>
              <td colSpan={2}>
                <p className="tips">主に扱う施術を最大5個まで , で区切って入力してください</p>
              </td>
            </tr>

            <tr>
              <td className="lefttd"><label htmlFor="transitionUrl">レビュー先URL:</label></td>
              <td>
                <input
                  type="text"
                  id="transitionUrl"
                  value={transitionUrl}
                  onChange={(e) => setReviewUrl(e.target.value)}
                  required
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p className="tips">例: Googleレビューや食べログのURL</p>
              </td>
            </tr>

            <tr>
              <td className="lefttd"><label>お客様への質問:</label></td>
              <td>
                <div className="radio-group">
                  <div className="radio-item">
                    <input
                      type="radio"
                      id="decideSelf"
                      value="自分で決める"
                      checked={questionPreference === '自分で決める'}
                      onChange={(e) => setQuestionPreference(e.target.value)}
                    />
                    <label htmlFor="decideSelf">自分で決める</label>
                  </div>
                  <div className="radio-item">
                    <input
                      type="radio"
                      id="leaveIt"
                      value="お任せする"
                      checked={questionPreference === 'お任せする'}
                      onChange={(e) => setQuestionPreference(e.target.value)}
                    />
                    <label htmlFor="leaveIt">お任せする</label>
                  </div>
                </div>
              </td>
            </tr>
            {questionPreference === 'お任せする' && (
              <>
                <tr>
                  <td colSpan={2}>
                    <p className="tips">「自分で決める」を選択した場合、質問を自由に設定できます</p>
                  </td>
                </tr>
              </>
            )}
            {questionPreference === '自分で決める' && (
              <>
                <tr>
                  <td className="lefttd"><label htmlFor="question1">質問1:</label></td>
                  <td>
                    <input
                      type="text"
                      id="question1"
                      value={question1}
                      onChange={(e) => setQuestion1(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="lefttd"><label htmlFor="question2">質問2:</label></td>
                  <td>
                    <input
                      type="text"
                      id="question2"
                      value={question2}
                      onChange={(e) => setQuestion2(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="lefttd"><label htmlFor="question3">質問3:</label></td>
                  <td>
                    <input
                      type="text"
                      id="question3"
                      value={question3}
                      onChange={(e) => setQuestion3(e.target.value)}
                    />
                  </td>
                </tr>
              </>
            )}

          </tbody>
        </table>
        <button type="submit" className="submit-button">登録する</button>
      </form>

      {/* 確認ダイアログ */}
      {showConfirmDialog && (
        <div className="confirm-dialog">
          <div className="confirm-dialog-content">
            <h3>確認</h3>
            <p>業種: {industry}</p>
            <p>店舗名: {storeName}</p>
            <p>施術内容: {treats.join(", ")}</p>
            <p>レビュー先URL: {transitionUrl}</p>
            <p>質問設定: {questionPreference}</p>
            {questionPreference === '自分で決める' && (
              <>
                <p>質問1: {question1}</p>
                <p>質問2: {question2}</p>
                <p>質問3: {question3}</p>
              </>
            )}
            <p>上記内容で登録しますか？</p>
            <button onClick={confirmSubmit} className="btn-confirm">はい</button>
            <button onClick={cancelSubmit} className="btn-cancel">いいえ</button>
          </div>
        </div>
      )}

      {/* ローディング中はくるくる回るアニメーションを表示 */}
      {isSubmitting && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
};

export default StartForm;
